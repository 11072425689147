import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	position: relative;
	width: 93%;

	@media screen and (min-width: 1440px)
	{
		max-width: 1440px;
	}
`;

export const Full = styled.div`
    width: 100%;
`;

export const Row = styled(Full)`
    display: flex;
    justify-content: ${props => props.justify? props.justify : 'center'};
    align-items: ${props => props.align? props.align : 'center'};
`;

export const RowCenter = styled(Full)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Column = styled(Full)`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justify? props.justify : 'center'};
    align-items: ${props => props.align? props.align : 'center'};
`;

export const ColumnCenter = styled(Full)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const WhiteSpace = styled.div`
    height: ${props => props.height? props.height : '8'}px;
`;