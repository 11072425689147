import React from 'react';
import styled from 'styled-components';
import SVGLoader from '../assets/loader';

import { ColumnCenter } from './GlobalStyles';

const LoaderContainer = styled(ColumnCenter)`
	display: flex;
	width: 100%;
	height: 100px;
	color: ${props => props.theme.loaderColor};

	svg {
        width: 100%;
        height: 100%;
    }
`;

const Loader = () => {
  	return (
		<LoaderContainer>
			<SVGLoader/>
		</LoaderContainer>
	)
}

export default Loader;