import axios from 'axios';

let headers = {
    "authorization": "marte1309",
    "device_id": "gimavLanding"
}

const axiosRequest = {
    get(endpoint, data) {
        return fetch("/config.json")
            .then((res) => res.json())
            .then((config) => {
                const savedUser = () => JSON.parse(localStorage.getItem('user') || '{}');

                if (savedUser().token) {
                    headers.accessToken = savedUser().token;
                }
        
                return axios.get(`${config.url_api}${endpoint}`, {
                    params: data,
                    headers
                })
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return { error: true, message: error.message };
                });
            });
    }
}

export default axiosRequest;
