import React from 'react';
import styled from 'styled-components';

import LogoImg from '../images/nickelodeon.png';

import {
	Container,
	ColumnCenter,
	Column,
	Row
} from './GlobalStyles';

const HeaderContainer = styled.header`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #FFF;
	height: 65px;
	box-shadow: -1px 2px 5px 0px rgba(164,164,164,0.75);
	-webkit-box-shadow: -1px 2px 5px 0px rgba(164,164,164,0.75);
	-moz-box-shadow: -1px 2px 5px 0px rgba(164,164,164,0.75);
`;

const Logo = styled.img`
	max-width: 100%;
	height: auto;
	width: 150px;
    padding: 0 8px;
`;

const LanguageContainer = styled.div`
	display: flex;
`;

const Language = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: ${props => props.active? props.theme.primaryColor : ''};
	color: ${props => props.active? '#FFFFFF' : ''};
	height: 65px;
	width: 45px;
`;

const HomeTab = styled.a`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	height: 65px;
	background-color: ${props => props.theme.primaryColor};
	margin-left: 16px;
	font-size: 1rem;
	font-weight: bold;
	color: #FFF;
`;

function Header(props) {
  	return (
		<HeaderContainer>
			<Container>
				<Column align="flex-start">
					<Logo className="logo" src={props.logo} alt="GIMAV picture"/>
				</Column>
				<ColumnCenter>
					<Row justify="flex-end">
						<LanguageContainer>
							<Language active={props.language === 'en'} onClick={() => props.setLanguage('en')}>EN</Language>
							<Language active={props.language === 'es'} onClick={() => props.setLanguage('es')}>ES</Language>
						</LanguageContainer>
						<HomeTab>Home</HomeTab>
					</Row>
				</ColumnCenter>
			</Container>
		</HeaderContainer>
	)
}

export default Header;
