import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.theme.footerBackColor};
	color: white;
	min-height: 200px;
	font-family: sans-serif;
`;

function Footer() {
  	return (
		<FooterContainer>
			<div className="column container full justify-center align-center">
				<div className="white-space-64"></div>
				<p>Copyright 2021 GIMAV Picture</p>
				<div className="white-space-64"></div>
			</div>
		</FooterContainer>
	)
}

export default Footer;