import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Request from "superagent";
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import WelcomeImg from "../images/welcome.png";
import CamaraImg from "../images/camara.png";
import Icon1 from "../images/icon1.png";
import Icon2 from "../images/icon2.png";
import Icon3 from "../images/icon3.png";
import IconFIle from "../images/iconfile.png";
import IconArrow from "../images/iconarrow.png";
import IconDownload from "../images/icondownload.png";

import {
  Container,
  WhiteSpace,
  Row,
  RowCenter,
  Column,
  ColumnCenter,
} from "../components/GlobalStyles";

const languageText = {
  es: {
    welcome: "¡Bienvenido!",
    download1:
      "Para descargar sus imágenes, siga las instrucciones a continuación",
    celortablet: "Celular o tablet",
    pcordlaptop: "PC o Laptop",
    selectphoto: "Seleccionar imágen",
    selecttheimage:
      "Seleccione la imagen para descargar y presione el icono de descarga.",
    hovertheimage:
      "Coloca el cursor sobre la imagen para descargar y presiona el ícono de descarga.",
    clickonthephoto:
      "Haga clic en la foto para seleccionar, presione el botón DESCARGAR SELECCIONADAS para descargar las imágenes seleccionadas.",
    downloadAll: "DESCARGAR SELECCIONADAS",
  },
  en: {
    welcome: "WELCOME!",
    download1: "To download your pictures follow the instructions below",
    celortablet: "Cell phone or tablet",
    pcordlaptop: "PC or Laptop",
    selectphoto: "Select photo",
    selecttheimage: "Select the image to download and press the download icon.",
    hovertheimage: "Hover the image to download and press the download icon.",
    clickonthephoto:
      "Click on the photo to select, press the DOWNLOAD SELECTED button to download selected images.",
    downloadAll: "DOWNLOAD SELECTED",
  },
};

const HomeContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 140px;

  h5 {
    font-size: 1.2rem;
    color: #767676;
  }

  p {
    font-size: 1.6rem;
    color: #767676;
  }

  @media screen and (max-width: 1280px) {
    padding-top: 320px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 320px;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  grid-auto-rows: minmax(100px, auto);
  z-index: 2;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CardInfo = styled(ColumnCenter)`
  width: auto;
  padding: 24px 32px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 5px 14px 2px rgba(229, 229, 229, 0.587);
  -webkit-box-shadow: 2px 5px 14px 2px rgba(229, 229, 229, 0.587);
  -moz-box-shadow: 2px 5px 14px 2px rgba(229, 229, 229, 0.587);

  img {
    margin-bottom: 16px;
  }

  h5 {
    font-size: 1.2rem;
    color: #767676;
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    margin-top: 16px;
    text-align: center;
  }
`;

const Welcome = styled.h1`
  font-size: 4rem;
  color: ${(props) => props.theme.welcomeColor};
`;

const ImgWelcome = styled.img`
  right: 6%;
  position: absolute;
  top: 12px;
  width: 19%;

  @media screen and (max-width: 1280px) {
    right: 38%;
    position: absolute;
    top: 12px;
    width: 20%;
  }

  @media screen and (max-width: 992px) {
    right: 36%;
    position: absolute;
    top: 12px;
    width: 24%;
  }

  @media screen and (max-width: 768px) {
    right: 19%;
    position: absolute;
    top: 10px;
    width: 49%;
  }
`;

const ImgCamera = styled.img`
  width: 42px;
  margin-right: 8px;
`;

const FotosContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  grid-auto-rows: minmax(100px, auto);
  width: 100%;
  z-index: 2;

  @media screen and (max-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FotoCard = styled(Column)`
  position: relative;
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 300ms ease-in-out;
    background-color: rgba(246, 99, 9, 0.5490196078431373);
    justify-content: center;
    align-items: center;
    display: flex;

    h1 {
      color: white;
    }

    .icon {
      width: 10%;
    }
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
    .selected {
      opacity: 0;
    }
  }
`;

const FotoSelect = styled(Row)`
  position: relative;
  background-color: #f66309;
  height: 30px;
`;

const CheckboxContainer = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.5s ease-in-out;

  p {
    font-size: 0.8rem;
    color: ${(props) =>
      props.theme.photoSelectorColor
        ? props.theme.photoSelectorColor
        : "white"};
    padding: 1px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    background-color: #eee;
    position: relative;
  }

  input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #59ca62;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ButtonsRow = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 0;
  position: sticky;
  bottom: 10px;
  z-index: 99;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: #3ba243;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 24px;
    cursor: pointer;
    padding: 12px 32px;
    text-align: center;
    border-radius: 32px;
    transition: all 0.3s ease-in-out;
    box-shadow: 2px 9px 6px 6px hsl(0deg 0% 63% / 75%);
    border: 0;

    img {
      margin-left: 16px;
      width: 40px;
      max-width: 100%;
    }
  }
`;

function Home({ logo, welcomeImg }) {
  const [fotos, setFotos] = useState([]);
  const [auxVideosBlob, setAuxVideosBlob] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState([]);
  const [selected, setSelected] = useState([]);
  const [language, setLanguage] = useState("en");
  const [textos, setTextos] = useState(languageText.en);

  const host =
    process.env.NODE_ENV === "production"
      ? "https://gimav-images.herokuapp.com"
      : "https://gimav-images.herokuapp.com";

  const params = useParams();

  useEffect(() => {
    getFotos();
  }, []);

  useEffect(() => {
    if (language === "es") {
      setTextos(languageText.es);
    } else {
      setTextos(languageText.en);
    }
  }, [language]);

  async function getFotos() {
    setLoading(true);
    await Request.get(`${host}/imagenes/get/bucket?url=${params.qr}/`)
      .set("sitio", `${params.sitio}`)
      .then(async (res) => {
        if (res.body) {
          if (Array.isArray(res.body)) {
            let newFotos = res.body.map((f) => {
              return {
                url: `${host}/imagenes/get/imagen/${params.sitio}/${f.Key}`,
                key: f.Key,
                name: f.name,
              };
            });

            const videosObj = newFotos.filter(
              (o) => o.key.split(".").pop() === "mp4"
            );

            if (videosObj) {
              generateUrlVideo(videosObj, newFotos);
            } else {
              setFotos(newFotos);
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const generateUrlVideo = async (data, resBody) => {
    let auxFETCH = [];

    for (let i = 0; i < data.length; i++) {
      await fetch(`${host}/imagenes/download/${params.sitio}/${data[i].key}`, {
        method: "GET",
        headers: {
          sitio: `${params.sitio}`,
        },
      })
        .then((response) => response.blob())
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          auxFETCH.push({ ...data[i], url: objectURL });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const newArr = resBody.map((obj) => {
      const fetchResponse = auxFETCH.filter((x) => x.key === obj.key);
      console.log(fetchResponse);
      if (fetchResponse.length > 0) {
        console.log("is true", true);
        return {
          ...obj,
          url: fetchResponse[0].url,
        };
      }
      return obj;
    });

    setFotos(newArr);
    setLoading(false);
  };

  const download = async (foto) => {
    let imagesDownloading = [...loadingDownload];
    imagesDownloading.push(foto);
    setLoadingDownload([...imagesDownloading]);

    await fetch(`${host}/imagenes/download/${params.sitio}/${foto}`, {
      method: "GET",
      headers: {
        sitio: `${params.sitio}`,
      },
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", foto); //or any other extension
          document.body.appendChild(link);
          link.click();
        });

        imagesDownloading = imagesDownloading.filter((iDown) => iDown !== foto);
        setLoadingDownload([...imagesDownloading]);
      })
      .catch((err) => {
        console.log(err);

        imagesDownloading = imagesDownloading.filter((iDown) => iDown !== foto);
        setLoadingDownload([...imagesDownloading]);
      });
  };

  const selectFoto = (foto) => {
    let selectedPhotos = [...selected];
    if (selectedPhotos.find((f) => f === foto)) {
      selectedPhotos = selectedPhotos.filter((f) => f !== foto);
    } else {
      selectedPhotos.push(foto);
    }

    setSelected([...selectedPhotos]);
  };

  const downloadAll = async () => {
    if (selected.length > 0) {
      setLoadingAll(true);
      for (let index in selected) {
        const foto = selected[index];
        await download(foto);
      }
      setLoadingAll(false);
    } else {
      /*await Request
			.get(`${host}/imagenes/downloadall/?qr=${params.qr}/`)
			.then((res) => {
				if(res.text) {
        			var buf = Buffer.from(res.text);
					console.log(buf);
				}
			})
			.catch(err => {
				console.error(err);
			});*/
      setLoadingAll(true);
      /*const url = `${host}/imagenes/downloadall/?qr=${params.qr}/`
			const anchor = document.createElement("a");
			anchor.href = url;
			anchor.target = "_newtab";
			anchor.download = 'AquaNick.zip';
			anchor.click();
			window.URL.revokeObjectURL(url);*/
      fetch(`${host}/imagenes/downloadall/?qr=${params.qr}/`, {
        method: "GET",
        headers: {
          sitio: `${params.sitio}`,
        },
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          setLoadingAll(true);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = `${params.sitio}.zip`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          alert("your file has downloaded!"); // or you know, something with better UX...
          setLoadingAll(false);
        })
        .catch(() => {
          alert("oh no!");
          setLoadingAll(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Header setLanguage={setLanguage} language={language} logo={logo} />

      <HomeContent>
        <Container>
          <Banner>
            <ImgWelcome src={welcomeImg} alt="Welcome" />
            <Welcome>{textos.welcome}</Welcome>
            <WhiteSpace height="8" />
            <RowCenter>
              <ImgCamera className="camera" src={CamaraImg} alt="camera" />
              <p>{textos.download1}</p>
            </RowCenter>
            <WhiteSpace height="64" />
            <WhiteSpace height="32" />
            <CardContainer>
              <CardInfo>
                <img src={Icon1} />
                <h5>{textos.celortablet}</h5>
                <p>{textos.selecttheimage}</p>
              </CardInfo>
              <CardInfo>
                <img src={Icon2} />
                <h5>{textos.pcordlaptop}</h5>
                <p>{textos.hovertheimage}</p>
              </CardInfo>
              <CardInfo>
                <img src={Icon3} />
                <h5>{textos.selectphoto}</h5>
                <p>{textos.clickonthephoto}</p>
              </CardInfo>
            </CardContainer>

            <WhiteSpace height="64" />

            {loading ? (
              <Loader />
            ) : (
              <FotosContainer>
                {fotos.map((foto, index) => (
                  <FotoCard key={index} onClick={() => selectFoto(foto.key)}>
                    {foto.key.split(".").pop() === "mp4" ? (
                      <ReactPlayer
                        url={foto.url}
                        muted
                        playing
                        loop
                        width="100%"
                      />
                    ) : (
                      <img src={foto.url} alt="preview" />
                    )}

                    <div className="overlay">
                      {loadingDownload.find((iDown) => iDown === foto.key) ? (
                        <Loader />
                      ) : (
                        <img
                          src={IconDownload}
                          className="icon"
                          onClick={(e) => download(foto.key)}
                        />
                      )}
                    </div>
                    <FotoSelect onClick={() => selectFoto(foto.key)}>
                      <CheckboxContainer onClick={() => selectFoto(foto.key)}>
                        <p onClick={() => selectFoto(foto.key)}>{foto.name} </p>
                        <input
                          type="checkbox"
                          checked={
                            selected.find((iDown) => iDown === foto.key)
                              ? true
                              : false
                          }
                        />
                        <span className="checkmark"></span>
                      </CheckboxContainer>
                    </FotoSelect>
                    {/*selected.find(iDown => iDown === foto.key) &&
											<div className="selected">
												<img src={IconFIle} className="icon" />
											</div>*/}
                  </FotoCard>
                ))}
              </FotosContainer>
            )}

            <WhiteSpace height="64" />
            <ButtonsRow>
              {loadingAll ? (
                <button className="btn success">
                  <div className="btn-loading">
                    <svg class="spinner" viewBox="0 0 50 50">
                      <circle
                        class="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke-width="5"
                      ></circle>
                    </svg>
                  </div>
                </button>
              ) : (
                selected.length > 0 && (
                  <button className="btn success" onClick={() => downloadAll()}>
                    {textos.downloadAll}
                    <img src={IconFIle} />
                  </button>
                )
              )}
              {/*<a className="btn send">
								SHARE YOUR EXPERIENCE
								<img src={IconArrow} />
							</a>*/}
            </ButtonsRow>
            <WhiteSpace height="64" />
          </Banner>
        </Container>
      </HomeContent>

      <Footer />
    </React.Fragment>
  );
}

export default Home;
