import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import request from './api/api';

import Homepage from './pages/Home';

import Loader from './components/Loader';

const GlobalStyle = createGlobalStyle`
	* {
		padding: 0;
		margin: 0;
	}

	html {
		font-size: 100%;
		font-display: swap;
	}

	body {
		margin: 0px;
		padding: 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-family: futura-pt,sans-serif;
	}

	a, b, li, p, span {
		line-height: 22px;
		font-size: .93rem;
	}
`;

function App() {
	const [loading, setLoading] = useState(true);
	const [logo, setLogo] = useState('');
	const [welcomeImg, setWelcomeImg] = useState('');
	const [theme, setTheme] = useState({
		themeColor: 'blue',
		primaryColor: '#94BB20',
		secondaryColor: '#011D41',
		footerBackColor: '#011D41',
		welcomeColor: '#94BB20',
		loaderColor: '#011D41'
	});

	const match = useRouteMatch();

	const { params, qr } = match;

	const getConfig = useCallback(
		(sitio) => {
			request.get('/sitios/configuracion', { sitio }).then(response => {
				if (response?.data) {
					setTheme(response.data);
					setLogo(response.data?.logo);
					setWelcomeImg(response.data?.welcomeImg);
					setLoading(false);
				}
			});
		},
		[],
	  );

	useEffect(() => {
		getConfig(params?.sitio);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			<Helmet>
				<title>GimavPicture</title>
				<meta name="theme-color" content={theme.themeColor || '#011D41'} />
			</Helmet>
			{loading
				? <Loader/>
				:<Homepage
					logo={logo}
					welcomeImg={welcomeImg}/>
			}
		</ThemeProvider>
	);
};

const Router = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/:sitio/share/:qr/" exact>
					<App/>
				</Route>
			</Switch>
		</BrowserRouter>
	)
};

export default Router;
